/* global inject */
inject(function ($) {
    'use strict';

    $.fn.updateCloneAttributes = updateCloneAttributes;

    function updateCloneAttributes(attributes) {
        var $elNew = this.clone(true);

        $elNew.updateAttributes(attributes);
        this.replaceWith($elNew);
        return $elNew;
    }
},
'$'
);
