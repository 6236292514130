/* global inject */
inject(function (Personalization, $, store, resolve) {
    'use strict';

    var Debug,

        // Log level binary flags
        NONE = 0,   // 00000
        ERROR = 1,  // 00001
        WARN = 2,   // 00010
        LOG = 4,    // 00100
        INFO = 8,   // 01000
        DEBUG = 16, // 10000
        ALL = ERROR | WARN | LOG | INFO | DEBUG; // jshint ignore:line

    /**
     * Initializes Debug object
     */
    function init() {
        var levels = Debug.level = {
            NONE: NONE,
            ALL: ALL,
            ERROR: ERROR,
            WARN: WARN,
            LOG: LOG,
            INFO: INFO,
            DEBUG: DEBUG
        };
        levels[ERROR] = {level: ERROR, method: 'error', prefix: 'ERROR:'};
        levels[WARN] = {level: WARN, method: 'warn', prefix: 'WARN:'};
        levels[LOG] = {level: LOG, method: 'log', prefix: 'LOG:'};
        levels[INFO] = {level: INFO, method: 'info', prefix: 'INFO:'};
        levels[DEBUG] = {level: DEBUG, method: 'debug', prefix: 'DEBUG:'};
        Object.freeze(levels);
    }

    /**
     * Parses and validates log level settings
     *
     * @param {string|number} level Number or a string level
     * @returns {number|undefined} logLevel Number or undefined when an argument cannot be parsed
     */
    function parseLogLevel(level) {
        if (typeof level === 'string') {
            if (level.match(/^(?:NONE|ERROR|WARN|LOG|INFO|DEBUG|ALL)$/)) {
                level = Debug.level[level];
            } else {
                level = parseInt(level, 10);
            }
        }

        return typeof level !== 'number' || isNaN(level) || level > ALL || level < NONE ? undefined : level;
    }

    /**
     * Logs a message to the console using specified method
     *
     * @param {string} method A console log method
     */
    function logConsole(method) {
        var args = Array.prototype.slice.call(arguments).slice(1);

        if (typeof console !== 'undefined' && typeof console[method] === 'function') {
            console[method].apply(console, args);
        }
    }

    /**
     * Locally logs messages at a specified level
     *
     * @param {number} level Debug level
     */
    function logLocal(level) {
        var args = Array.prototype.slice.call(arguments).slice(1);

        if (
            Debug.config.localLogLevel & level && // jshint ignore:line
            Debug.level[level]
        ) {
            logConsole.apply(Debug, [Debug.level[level].method].concat(args));
        }

        if (
            Debug.config.remoteLogLevel & level && // jshint ignore:line
            Debug.level[level]
        ) {
            logRemote.apply(Debug, [level].concat(args));
        }
    }

    /**
     * Remotely logs messages at a specified level
     *
     * @param {number} level Debug level
     */
    function logRemote(level) {
        var args = Array.prototype.slice.call(arguments).slice(1),
            postBody = {
                namespace: 'WDPRO.Personalization',
                version: Personalization.version,
                level: Debug.level[level].prefix,
                personalizationId: resolve('getPersonalizationId')(),
                timestamp: new Date().toISOString(),
                messages: args
            };

        $.post(
            Debug.config.remoteLogUri,
            JSON.stringify(postBody)
        ).error(function () {
            logConsole.apply(Debug, [Debug.level[DEBUG].method, 'P13n remote logging failure...'].concat(args));
        });
    }

    /**
     * Debug object that provides functionality for local and remote logging
     */
    Debug = store('Debug', {
        /**
         * Logging levels
         */
        level: {},

        /**
         * Debug configuration
         *
         * {number} localLogLevel Level of local logging
         * {number} remoteLogLevel Level of remote logging
         * {string} remoteLogUri Remote logging endpoint URI
         */
        config: {
            localLogLevel: ERROR | WARN, // jshint ignore:line
            remoteLogLevel: ERROR | WARN, // jshint ignore:line
            remoteLogUri: '/utils/log?splunk=true'
        },

        /**
         * Configures logger options
         *
         * @param {Object} options Configuration options
         */
        configure: function (options) {
            // validate essential configuration parameters
            options.localLogLevel = parseLogLevel(options.localLogLevel);
            options.remoteLogLevel = parseLogLevel(options.remoteLogLevel);
            options.remoteLogUri = typeof options.remoteLogUri === 'string' ? options.remoteLogUri : undefined;

            $.extend(Debug.config, options);
        },

        logAjaxTime: function (startTimeStamp, url, note) {
            var endTimeStamp = Date.now(),
                diff = (endTimeStamp - startTimeStamp);

            Debug.log(diff + 'ms - ' + note + ': ' + url);
            return diff;
        },

        /**
         * Logs warning messages
         */
        warn: function () {
            logLocal.apply(Debug, [WARN].concat(Array.prototype.slice.call(arguments)));
        },

        /**
         * Logs regular messages
         */
        log: function () {
            logLocal.apply(Debug, [LOG].concat(Array.prototype.slice.call(arguments)));
        },

        /**
         * Logs debug messages
         */
        debug: function () {
            logLocal.apply(Debug, [DEBUG].concat(Array.prototype.slice.call(arguments)));
        },

        /**
         * Logs error messages
         */
        error: function () {
            logLocal.apply(Debug, [ERROR].concat(Array.prototype.slice.call(arguments)));
        },

        /**
         * Logs informational messages
         */
        info: function () {
            logLocal.apply(Debug, [INFO].concat(Array.prototype.slice.call(arguments)));
        },

        /**
         * Remotely logs messages
         */
        logRemote: function () {
            var args = Array.prototype.slice.call(arguments),
                level = LOG;

            logRemote.apply(Debug, [level].concat(Array.prototype.slice.call(arguments)));

            if (
                Debug.config.localLogLevel & level && // jshint ignore:line
                Debug.level[level]
            ) {
                logConsole.apply(Debug, [Debug.level[level].method].concat(args));
            }
        }
    });

    init();
},
'Personalization',
'$',
'store',
'resolve'
);
