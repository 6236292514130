/* global inject, WDPRO, PEP, TESTING */
try {
    // jshint maxparams: 21
    inject(function (
        Personalization,
        $,
        Debug,
        PEP,
        timing,
        appendParams,
        getDataLayer,
        getAuthToken,
        getCookie,
        getJsonKey,
        getPageKey,
        getPersonalizationId,
        utilsMergeConfig,
        pollForElementStatus,
        getBoolean,
        getInt,
        setPersonalizationId,
        pollForElement,
        mustache,
        getConversationId,
        getPreferredLanguage,
        ensureArray
    ) {
        'use strict';

        // constants
        var P13N_COOKIE_JAR = 'personalization_jar',
            P13N_DONE_CLASS = 'personalization-done',
            P13N_RESPONSE_READY_EVENT = 'personalization.responseReady',
            P13N_DEBUG = 'p13nDebug',
            P13N_FPCSS_ID = 'p13nfp',
            P13N_LOCATIONS_COOKIE = 'p13n_locations',

        // variables
            authToken, // local reference to authToken
            config = { // these are the fall back defaults when not overridden by pageData or cookie
                additionalHeaders: {},
                debug: {
                    localLogLevel: Debug.level.NONE,
                    /* jshint -W016 */
                    remoteLogLevel: Debug.level.ERROR | Debug.level.WARN
                    /* jshint +W016 */
                },
                decisionTimeout: 500,
                decisionURL: '/wam/recommendation-service/personalized-content',
                disableP13nAuth: false,
                enableP13nRedirect: false,
                longTimeoutEnabled: true,
                personalizationIdCookieJarName: P13N_COOKIE_JAR,
                personalizationIdCookieName: 'id',
                //serviceResponseTime: 0 // artificially inflate response time from static service, time in milliseconds
                checkUpsellContent: false
            },
            deferredAnalyticsModelData,
            deferredDecisions,
            decisions,
            locationsFlickerPrevented, // local cache of responses in has table form for quicker future lookups
            timeoutRef,
            results;

        WDPRO.Analytics = WDPRO.Analytics || {};
        WDPRO.Analytics.additionalModelData = WDPRO.Analytics.additionalModelData || [];

        // expose public properties and methods
        $.extend(Personalization, {
            // properties
            ajaxCall: null, // save a reference to ajax calls so we can manually manage them later
            configsByLocationName: null,
            dataLayer: null,
            notVisibleElementSelectors: null,
            config: null,

            // methods
            reset: reset,
            executeWith: executeWith,
            init: init,
            setupDebug: setupDebug,
            setupLocations: setupLocations,
            getPersonalizedLocations: getPersonalizedLocations,
            checkForPersonalization: checkForPersonalization
        });

        // expose some methods only for unit testing purposes (won't be exposed in the built artifact)
        if (TESTING) {
            $.extend(Personalization, {
                alterElement: alterElement,
                alterElementPostProcess: alterElementPostProcess,
                getLocationsForRequest: getLocationsForRequest,
                getPageKeyLocations: getPageKeyLocations,
                mergePersonalizations: mergePersonalizations,
                parseDecisionData: parseDecisionData
            });
        }

        reset();
        init();

        // functions
        function reset(withConfig) {
            if (Personalization.ajaxCall) {
                Personalization.ajaxCall.abort();
            }
            authToken = undefined;
            resetGlobals();
            results = {};
            clearTimeout(timeoutRef);
            if (withConfig) {
                Personalization.config = $.extend(config, withConfig);
                mergeConfig();
            }
            setupDebug();
        }

        function resetGlobals() {
            if (deferredAnalyticsModelData && deferredAnalyticsModelData.resolve) {
                deferredAnalyticsModelData.resolve({});
            }
            deferredAnalyticsModelData = $.Deferred();
            Personalization.configsByLocationName = {};
            locationsFlickerPrevented = {};
            Personalization.notVisibleElementSelectors = [];
            Personalization.dataLayer = null;

            if (deferredDecisions && deferredDecisions.reject) {
                deferredDecisions.reject();
            }
            deferredDecisions = $.Deferred();
            decisions = deferredDecisions.promise();
        }

        /**
         *
         * @param {Object} model.configuration.CEA
         * @param {boolean} model.configuration.CEA.config.longTimeoutEnabled - optional should be false
         *
         * @param {array} model.personalizations
         * @param {string} model.geo.country
         * @param {string|number} model.geo.dmaCode
         * @param {string} model.geo.region
         * @param {string} model.pageId - TODO deal with this being generated differently in non-pep
         *
         * @param {string|number} model.age
         * @param {string|boolean} model.availableGuestMedia - phase 2
         * @param {string} model.customerId
         * @param {string|boolean} model.guestAffiliations
         *
         * @param {string|boolean} pageKey
         *
         */
        function executeWith(model, pageKey) {
            var deferred = $.Deferred(),
                testingLocations = getTestingLocations(pageKey);

            resetGlobals();
            mergePersonalizations(testingLocations, model);
            Personalization.config = mergeConfig(model);
            Personalization.dataLayer = model;

            WDPRO.Analytics.additionalModelData.push(
                deferredAnalyticsModelData,
                {personalizations: testingLocations}
            );
            setupDebug();
            setupLocations(model.personalizations);

            checkForPersonalization(getLocationsForRequest(Personalization.configsByLocationName))
                .then(function (response) {
                    var adaptedResponse;

                    document.dispatchEvent(new CustomEvent(P13N_RESPONSE_READY_EVENT, {detail: response}));
                    adaptedResponse = adaptResponse(response);
                    deferred.resolve(adaptedResponse);
                    handlePersonalizedContentResponse(adaptedResponse);
                }, deferred.reject);

            return deferred;
        }

        /**
         * begins the personalization execution
         */
        function init() {
            // jscs:disable requireVarDeclFirst
            if (TESTING) {
                // supports core.test.js spies and stubs
                getPageKey = inject.resolve('getPageKey');
                getDataLayer = inject.resolve('getDataLayer');
                getJsonKey = inject.resolve('getJsonKey');
            }
            var disabled,
                disabledPossibleAffiliations = ['Agent'],
                model = Personalization.dataLayer = getDataLayer() || {},
                pageKey = getPageKey(),
                pageKeyLocations = getPageKeyLocations(pageKey, model),
                respTime = timing ? timing.responseEnd - timing.requestStart : 'unknown',
                testingLocations = getTestingLocations(pageKey);
            // jscs:enable requireVarDeclFirst

            Debug.log('Page Response Time: %sms', respTime);

            model.personalizations = model.personalizations || [];
            WDPRO.Analytics.additionalModelData.push(
                deferredAnalyticsModelData,
                {personalizations: pageKeyLocations},
                {personalizations: testingLocations}
            );

            mergePersonalizations(pageKeyLocations, model);
            mergePersonalizations(testingLocations, model);

            // storing the config for debugging.  We should only use the private variable.
            Personalization.config = mergeConfig(model);
            setupDebug();
            setupLocations(model.personalizations);
            addFlickerPreventionStylesheet(Personalization.notVisibleElementSelectors);

            disabled = pageKey && (processRules(model) || !enableInDataLayer(model));

            if (!disabled && $.isPlainObject(model)) {
                if (disabledPossibleAffiliations.indexOf(model.possibleAffiliation) > -1) {
                    disabled = true;
                    Debug.logRemote(
                        'Disabled - affiliations match',
                        { affililations: disabledPossibleAffiliations }
                    );
                }
            }
            if (disabled) {
                // reveal any hidden elements
                handleRejection('user did not meet criteria for personalization');
            } else if ($.isEmptyObject(model.personalizations)) {
                handleRejection('no locations configured to personalize');
            } else {
                // config.longTimeoutEnabled may not fully be configured at this point,
                //   so it is moved inside document.ready
                if (config.longTimeoutEnabled) {
                    $(checkForFailure.bind(null, false));
                }

                checkForPersonalization(getLocationsForRequest(Personalization.configsByLocationName))
                    .then(function (response) {
                        handlePersonalizedContentResponse(adaptResponse(response));
                    });
            }
        }

        function handledInternally(itemConfig, $body) {
            return itemConfig &&
                (itemConfig.alterations ||
                    (itemConfig.alterationMethod && itemConfig.selector &&
                        $body[normalizeAlterationName(itemConfig.alterationMethod)]
                    )
                );
        }
        /**
         *
         * Convert the response to a generic object for easy lookup
         *
         * @param {Object} responseData
         *
         * @return {Object} in the format {locationId:relevantResponse}
         *
         */
        function adaptResponse(responseData) {
            var $body = $('body'),
                locations = {};

            $.each(responseData.locations, function (key, locationData) {
                var pcb = locationData.personalizedContentBlocks,
                    configByName = Personalization.configsByLocationName[key];

                if (!$.isEmptyObject(locationData)) {
                    if (!$.isEmptyObject(pcb)) {
                        locationData.personalizedContent = pcb[Object.keys(pcb)[0]];
                    }
                    delete locationData.personalizedContentBlocks;
                    locations[key] = locationData;
                    locations[key].willBeHandled = handledInternally(configByName, $body);
                }
            });

            deferredAnalyticsModelData.resolve(parseDecisionData(responseData.decisionData));

            return locations;
        }

        /*
         * check the data layer to see if decisions are enabled
         *
         * @param {object} dataLayer
         *
         * @return {boolean} is the decision request enabled
         */
        function enableInDataLayer(model) {
            var enabled = getJsonKey(model, 'configuration.CEA.enableDecision');
            if (enabled === undefined) {
                enabled = true;
            }
            return getBoolean(enabled);
        }

        /**
         * Look at the data layer and determine if there are any global personaliation locations
         * that need to be added to the personalizations array
         */
        function getPageKeyLocations(pageKey, model) {
            var locations = [];

            if (pageKey && model && model.pageKeyLocations) {
                pageKey = pageKey.replace(/\./g, '_');
                $.each(model.pageKeyLocations, function (matchKey, personalizations) {
                    if (matchesPageKey(pageKey, matchKey)) {
                        locations = locations.concat(personalizations);
                    }
                });
            }

            return locations;
        }

        /**
         * Check to see if segment is contained in pageKey
         */
        function matchesPageKey(pageKey, segment) {
            return pageKey.indexOf(segment) > -1;
        }

        /**
         * parse the decision block into a format that the analytics framework can ingest
         */
        function parseDecisionData(data) {
            var personalizations = [];

            if ($.isPlainObject(data) && Array.isArray(data.decisions)) {
                data.decisions.forEach(function (decision) {
                    var personalization = {},
                        personalizationOptions = [];

                    if ($.isPlainObject(decision)) {
                        personalization.customerGroup = decision.customerGroup;
                        personalization.location = decision.location;

                        if (Array.isArray(decision.options)) {
                            decision.options.forEach(function (option) {
                                var optionDoPush = true,
                                    optionNew = {};

                                if ($.isPlainObject(option)) {
                                    optionNew.decision = option.name;
                                    if (Array.isArray(option.properties)) {
                                        option.properties.forEach(function (property) {
                                            if ($.isPlainObject(property)) {
                                                switch (property.name) {
                                                    case 'location_type':
                                                        if (property.value === 'data_only') {
                                                            optionDoPush = false;
                                                        }
                                                        break;
                                                    case 'option_tracking_id':
                                                        optionNew.optionId = property.value;
                                                        break;
                                                    case 'vpc_tracking_id':
                                                        personalization.vpcSegment = property.value;
                                                        break;
                                                }
                                            }
                                        });
                                    }
                                    if (optionDoPush) {
                                        personalizationOptions.push(optionNew);
                                    }
                                }
                            });
                        }

                        if (personalizationOptions.length > 0) {
                            personalization.options = personalizationOptions;
                        }
                        personalizations.push(personalization);
                    }
                });
            }

            return personalizations.length ? {personalizations: personalizations} : {};
        }

        function setupDebug() {
            var debugCookie = getCookie(P13N_DEBUG),
                debugConfig = config.debug;

            if (typeof debugCookie !== 'undefined') {
                debugConfig.localLogLevel = debugCookie;
                debugConfig.remoteLogLevel = debugCookie;
            }

            Debug.configure(debugConfig);
        }

        /**
         *
         * Setup the globals for dealing with locations
         *
         * @param {array} locations
         *
         */
        function setupLocations(locations) {
            $.each(locations, function (key, location) {
                var selectors = [];

                if ($.isPlainObject(location) && location.location && !getBoolean(location.serverSide)) {
                    if (Array.isArray(location.alterations)) {
                        location.alterations.forEach(function (alteration) {
                            if (alteration.selector) {
                                selectors.push(alteration.selector);
                            }
                        });
                    } else if (location.selector) {
                        selectors.push(location.selector);
                    }
                    Personalization.configsByLocationName[location.location] = location;
                    if (selectors.length) {
                        locationsFlickerPrevented[location.location] = {selector: selectors.join(',')};
                        Personalization.notVisibleElementSelectors =
                            Personalization.notVisibleElementSelectors.concat(selectors);
                    }
                }
            });
        }

        function getPersonalizedLocations(locations) {
            var deferred = $.Deferred();

            if (!Array.isArray(locations)) {
                deferred.reject('array parameter expected');
            } else {
                decisions.then(
                    // Success
                    function (response) {
                        var result = {};
                        if (response.locations) {
                            $.each(locations, function (index, location) {
                                if (response.locations[location]) {
                                    result[location] = response.locations[location];
                                }
                            });
                        }
                        deferred.resolve(result);
                    },
                    // failure
                    deferred.reject
                );
            }

            return deferred.promise();
        }

        function checkForPersonalization(locations) {
            // variables
            var postBody = {
                forceDecisions: true,
                locations: locations,
                checkUpsellContent: !!config.checkUpsellContent
            };

            // methods
            function fetchPersonalizations() {
                var url = config.decisionURL;

                // Make call to Personalization service to get personalization for page
                $.ajax({
                    beforeSend: function (xhr) {
                        var conversationId = getConversationId(),
                            preferredLanguage = getPreferredLanguage(),
                            header;

                        Personalization.ajaxCall = xhr;
                        if (!config.disableP13nAuth) {
                            xhr.setRequestHeader('Authorization', 'BEARER ' + authToken);
                        }
                        if (conversationId) {
                            xhr.setRequestHeader('X-Conversation-Id', conversationId);
                        }
                        if (preferredLanguage) {
                            xhr.setRequestHeader('Accept-Language', preferredLanguage);
                        }
                        if (config.serviceResponseTime) {
                            xhr.setRequestHeader('X-Disney-Internal-Delay-Response-Millis',
                                config.serviceResponseTime);
                        }
                        for (header in config.additionalHeaders) {
                            if (config.additionalHeaders.hasOwnProperty(header)) {
                                xhr.setRequestHeader(header, config.additionalHeaders[header]);
                            }
                        }
                    },
                    contentType: 'application/json; charset=utf-8',
                    data: JSON.stringify(postBody),
                    dataType: 'json',
                    type: 'POST',
                    timeout: config.longTimeoutEnabled ? 0 : config.decisionTimeout,
                    url: url,
                    command: 'fetchPersonalizations'
                }).then(
                    function fetchPersonalizationsSuccess(response) {
                        var personalizationId = response.personalizationId ||
                            getJsonKey(response, 'decisionData.personalizationId');

                        if (personalizationId) {
                            setPersonalizationId(personalizationId);
                        }

                        deferredDecisions.resolve(response);
                    },
                    function fetchPersonalizationsFailure() {
                        handleRejection('call to personalization failed');
                    }
                );
            }

            if (!locations.length) {
                Debug.error('No locations provided');
                handleRejection('No locations provided');
            } else {
                appendParams(postBody);
                makeAPICall(fetchPersonalizations);
            }

            return deferredDecisions.promise();
        }

        /**
         *
         * Normalizes alteration method aliases. Converts all known alias names into supported jQuery method names.
         *
         * @param {string} alterationMethod Alteration name alias.
         *
         * @returns {string} Corresponding jQuery method name.
         *
         */
        function normalizeAlterationName(alterationMethod) {
            // method aliases
            switch (alterationMethod) {
                case 'replace':
                case 'swap':
                    alterationMethod = 'replaceWith';
                    break;
                case 'content':
                case 'replaceContent':
                    alterationMethod = 'html';
                    break;
            }

            return alterationMethod;
        }

        /**
         *
         * Defers execution of pollForElement promise for later.
         *
         * @param {string} selector CSS selector
         * @param {number} domReadyTimeout the number of milliseconds after domReady to timeout
         * @param {function} after what to do when the promise resolves
         *
         * @returns {function} A function that returns the pollForElement promise
         *
         */
        function pollForElementFunction(selector, domReadyTimeout, after) {
            return function () {
                return pollForElement(selector, domReadyTimeout).then(after);
            };
        }

        /**
         *
         * Defers the alterElement call until after the prerequisites are met and to allow ordering of the alters
         *
         * @param {Object} alterConfig The alteration configuration
         *
         * @returns {function} A function that executes the alteration after validating prerequisites
         *
         */
        function deferAlteration(alterConfig) {
            var alterFn = pollForElementFunction(alterConfig.selector, alterConfig.domReadyTimeout, function ($elm) {
                    alterElement($elm, alterConfig);
                });

            return alterConfig.requiredElement ?
                pollForElementFunction(alterConfig.requiredElement, alterConfig.domReadyTimeout, alterFn) :
                alterFn;
        }

        /**
         *
         * Wraps every alteration promise into a utility function for positive resolution regardless
         * of whether an alteration promise itself was resolved or rejected.
         *
         * @param {array} items An array of alterations.
         *
         * @returns {array} An array of wrapped promises.
         *
         */
        function getAlterationPromises(items) {
            var polledElements = [];

            $.each(items, function (index, item) {
                polledElements.push(
                    pollForElementStatus('altered', item.getPromise(), item.selector)
                );
            });

            return polledElements;
        }

        // private functions
        /**
         *
         * handle personalized content service response
         *
         * @param {Object} response - personalized content service response body
         *
         */
        function handlePersonalizedContentResponse(response) {
            var CONTENT_KEY = 'descriptions.overviewShortDescription.text',
                locationAlterationConfig,
                locationAlterationConfigs,
                locationsToAlter = {},
                locationsFlickerPreventedNoResponse = $.extend({}, locationsFlickerPrevented),
                polledElements = [],
                prioritizedAlterationGroups = [],
                queuedAlterations;

            // A convenience method for the array
            prioritizedAlterationGroups.add = function (alteration) {
                var i;

                for (i = this.length - 1; i >= 0; i--) {
                    if (this[i][0].priority < alteration.priority) {
                        if (i + 1 >= this.length) {
                            this.push([alteration]);
                        } else {
                            this.splice(i + 1, 0, [alteration]);
                        }
                        break;
                    } else if (this[i][0].priority === alteration.priority) {
                        this[i].push(alteration);
                        break;
                    }
                }

                if (i < 0) {
                    this.unshift([alteration]);
                }
            };

            $.extend(locationsToAlter, getForcedAlterations(Personalization.configsByLocationName));
            $.extend(locationsToAlter, response);

            $.each(locationsToAlter, function (locationName, locationValue) {
                var contentKey,
                    skip;

                locationAlterationConfig = $.extend({}, Personalization.configsByLocationName[locationName]);
                if (!Array.isArray(locationAlterationConfig.alterations)) {
                    locationAlterationConfigs = [locationAlterationConfig];
                } else {
                    locationAlterationConfigs = locationAlterationConfig.alterations;
                }

                locationAlterationConfigs.forEach(function (alterationConfig) {
                    alterationConfig.location = locationName;

                    updateAttributeForView(alterationConfig, 'alterationMethod', '');
                    alterationConfig.alterationMethod = normalizeAlterationName(alterationConfig.alterationMethod);

                    expandTemplateValues(alterationConfig, {
                        location: locationValue,
                        alterationConfig: alterationConfig,
                        response: response,
                        dataLayer: Personalization.dataLayer
                    });

                    if (!$.isEmptyObject(locationValue)) {
                        // remove locations that we have responses for
                        if (locationsFlickerPreventedNoResponse[locationName]) {
                            delete locationsFlickerPreventedNoResponse[locationName];
                        }
                        // setup the content
                        updateAttributeForView(alterationConfig, 'contentKey', CONTENT_KEY);
                        contentKey = alterationConfig.contentKey;
                        alterationConfig.content = getJsonKey(locationValue.personalizedContent, contentKey);
                    } else {
                        skip = true;
                    }

                    if (locationValue.personalizedResortOnesource) {
                        skip = false;
                        alterationConfig.content = locationValue.personalizedResortOnesource;
                    }

                    if ($.isPlainObject(alterationConfig.sortConfig)) {
                        skip = false;
                        alterationConfig.content = $.extend({
                                order: locationValue.personalizedSortOrder,
                                select: alterationConfig.sortConfig.selector
                            },
                            alterationConfig.sortConfig
                        );
                    }

                    if (skip && $.isArray(locationValue.personalizedRoomTypes)) {
                        skip = false;
                    }

                    if (alterationConfig.selector && !skip) {
                        prioritizedAlterationGroups.add(setAlterationConfigPriority(alterationConfig));
                    }
                });
            });

            queuedAlterations = queueAlterations(prioritizedAlterationGroups);
            if (queuedAlterations) {
                polledElements.push(queuedAlterations);
            }
            polledElements = revealConfigured(locationsFlickerPreventedNoResponse).concat(polledElements);
            $.when.apply($, polledElements)
                .then(function () {
                    markDone();
                    $(document).trigger('pollForElementsComplete.personalization', [results]);
                });
        } // function handlePersonalizedContentResponse

        /**
         *
         * Make modifications to an element based on the config
         *
         * @param {Object} $targetElement - the jQuery element to make modifications to
         * @param {Object} config
         * @param {Object} config.alterationMethod - the alteration method to apply to $targetElement
         * @param {Object} config.content - the HTML from personalized modify $targetElement with
         * @param {Object} [config.contentSelector] - content use in a move
         * @param {Object} config.location - the location name used in dataLayer / CEA
         *
         */
        function alterElement($targetElement, config) {
            var alterationMethod = config.alterationMethod,
                contents = config.content || '',
                $newTargetElement = $targetElement,
                contentNodes;

            if (TESTING) {
                // supports core.test.html
                pollForElement = inject.resolve('pollForElement');
                alterElementPostProcess = Personalization.alterElementPostProcess;
            }

            if ($targetElement.length === 1 && $targetElement[alterationMethod]) {
                if (config.contentSelector) { // this is a move
                    pollForElement(
                        (config.contentSelectorAncestor && config.contentSelectorBase) || config.contentSelector,
                        config.domReadyTimeout
                    ).then(
                        function ($moveTarget) {
                            if (config.contentSelectorBase && config.contentSelectorAncestor) {
                                $moveTarget = $moveTarget
                                    .closest(config.contentSelectorAncestor)
                                    .find(config.contentSelector);
                            }

                            $targetElement[alterationMethod](
                                config.cloneContent ? ($moveTarget = $moveTarget.clone(true)) : $moveTarget.detach()
                            );
                            alterElementPostProcess($moveTarget, config);
                        },
                        function () {
                            revealFlickerPreventedLocation(config.location);
                        }
                    );
                } else if (alterationMethod === 'remove') {
                    $newTargetElement = $targetElement.parent();
                    $targetElement[alterationMethod]();
                    alterElementPostProcess($newTargetElement, config);
                } else if (alterationMethod === 'updateCloneAttributes') {
                    $newTargetElement = $targetElement[alterationMethod](contents);
                    alterElementPostProcess($newTargetElement, config);
                } else if (alterationMethod === 'attr') {
                    if (typeof contents !== 'string') {
                        contents = JSON.stringify(contents);
                    }
                    $newTargetElement = $targetElement[alterationMethod](config.alterationParam, contents);
                    alterElementPostProcess($newTargetElement, config);
                } else {
                    if (typeof contents === 'string') {
                        contentNodes = $.parseHTML(contents.trim());
                        if (contentNodes && contentNodes.length === 1 && contentNodes[0] instanceof HTMLElement) {
                            $newTargetElement = contents = $(contentNodes);
                        } else if (alterationMethod !== 'html') {
                            $newTargetElement = $targetElement.parent();
                        }
                    }
                    if (contents) {
                        $targetElement[alterationMethod](contents);
                    }
                    $targetElement = $newTargetElement;

                    alterElementPostProcess($targetElement, config);
                }
            } else {
                revealFlickerPreventedLocation(config.location);
            }
        } // function alterElement

        /**
         *
         * Clean up after altering an element - addClass for debug, reveal, pepAutoPlugin, etc.
         *
         * @param {Object} $targetElement - the jQuery element that was modified
         * @param {Object} config - the configuration used by the alteration
         * @param {string} config.location  - the location name used in dataLayer / CEA
         * @param {boolean} config.processPlugins  - should we run pepAutoPlugin on the element
         *
         */
        function alterElementPostProcess($targetElement, config) {
            var location = config.location,
                pepAutoAttr = 'data-plugins',
                targetElement,
                $el;

            // Add class & data used by personalization bookmarklet to identify personalized locations
            $targetElement.addClass('personalized-content');
            $targetElement.data('personalized-content-data', config);

            // reveal the potentially hidden content that was hidden via data layer

            if ($targetElement.prop('tagName').toLowerCase() === 'img') {
                if ($targetElement[0].complete) {
                    revealFlickerPreventedLocation(location);
                } else {
                    $targetElement.one('load', function () {
                        revealFlickerPreventedLocation(location);
                    });
                }
            } else {
                revealFlickerPreventedLocation(location);
            }

            if (config.processPlugins && PEP.loaded) {
                if (typeof window.jQuery === 'function') {
                    targetElement = $targetElement.get(0);
                    $el = window.jQuery(targetElement).find('[' + pepAutoAttr + ']');
                    if ($targetElement.attr(pepAutoAttr)) {
                        $el = $el.add(targetElement);
                    }
                    if ($el.length > 0 && $el.pepAutoPlugin) {
                        $el.pepAutoPlugin();
                    }
                }
            }

            ensureArray(config.successAlteration).forEach(function (alteration) {
                $el = $(alteration.selector);
                if ($el[alteration.method]) {
                    $el[alteration.method].apply($el, ensureArray(alteration.args));
                }
            });

        } // function alterElementPostProcess

        /**
         *
         * Self calling method to check to see if the calls have been made and if we should manually abort them.
         *
         * @param {boolean} alreadyChecked - have we checked before
         */
        function checkForFailure(alreadyChecked) {
            var ajaxCall = Personalization.ajaxCall;

            if (authToken) {
                if (ajaxCall && ajaxCall.state() !== 'resolved') {
                    if (alreadyChecked) {
                        handleTimeoutManually();
                    } else {
                        timeoutRef = setTimeout(checkForFailure, config.decisionTimeout, true);
                    }
                } else {
                    revealFlickerPreventedContent();
                }
            } else {
                if (alreadyChecked) {
                    handleTimeoutManually();
                } else {
                    timeoutRef = setTimeout(checkForFailure, config.decisionTimeout, true);
                }
            }
        } // function checkForFailure

        /**
         *
         * @param {Object} locationConfig an object with keys of personalization locations
         * @returns {Array} of objects in the format needed for service call
         */
        function getLocationsForRequest(locationConfig) {
            return Object.keys(locationConfig).map(function (locationName) {
                var location = locationConfig[locationName],
                    contentId = location.contentId,
                    optionCount = getInt(location.optionCount),
                    requestLocation =  {
                        id: locationName,
                        shouldFetchContent: location.alterationMethod !== 'orderElements'
                    };

                if (optionCount) {
                    requestLocation.requiredOptionsCount = optionCount;
                }

                if (getBoolean(location.decisionOnly)) {
                    requestLocation.shouldFetchContent = false;
                    requestLocation.shouldFetchDecision = true;
                }

                if (contentId) {
                    requestLocation.contentIds = [contentId];
                    requestLocation.shouldFetchContent = true;
                    requestLocation.shouldFetchDecision = false;
                }

                config.checkUpsellContent = config.checkUpsellContent || location.hasUpsellContent;

                return requestLocation;
            });
        }

        /**
         *
         * consolidated promise rejection
         *
         * @param {string} reason - the reason to give when rejecting
         *
         */
        function handleRejection(reason) {
            deferredAnalyticsModelData.resolve({});
            deferredDecisions.reject(reason);
            markDone();
        } // function handleRejection

        /**
         *
         * Method to call when the service calls have not completed quickly enough
         *
         */
        function handleTimeoutManually() {
            var ajaxCall = Personalization.ajaxCall;

            if (ajaxCall) {
                ajaxCall.abort();
            }
            handleRejection('calls did not complete in time');
        } // function handleTimeoutManually

        /**
         *
         * Sets a class on the body to indicate that personalization has completed
         *
         */
        function markDone() {
            revealFlickerPreventedContent();
            // the body might not exist when this is fired, so add the class on document ready
            $(document).ready(function () {
                $('body').addClass(P13N_DONE_CLASS);
            });
        } // function markDone

        /**
         *
         * Wrapper to ensure the user has an authToken before making the call to personalized content service
         *
         * @param {function} serviceCall - the function that makes the call to personalized content service
         */
        function makeAPICall(serviceCall) {
            if (!config.disableP13nAuth) {
                getAuthToken(config).then(
                    // Success
                    function (authResponseToken) {
                        authToken = authResponseToken;
                        serviceCall();
                    },
                    // Failure
                    function () {
                        handleRejection('auth token failure');
                    }
                );
            } else {
                serviceCall();
            }
        } // function makeAPICall

        /**
         * Add additional headers to the service call based on settings in apiGroups cookie
         *
         * @param {Object} headers - where to add the overrides headers
         */
        function addServiceOverrides(headers) {
            var OVERRIDES = [
                    {
                        prop: 'cea',
                        header: 'X-Disney-Internal-Environment-Change-Override-PersonalizationDAO'
                    },
                    {
                        prop: 'ecm',
                        header: 'X-Disney-Internal-Environment-Change-Override-ECMContentDAO'
                    }
                ],
                apiGroups = getCookie('apiGroups', true) || {};

            OVERRIDES.forEach(function (pair) {
                if (apiGroups[pair.prop]) {
                    headers[pair.header] = apiGroups[pair.prop];
                }
            });
        } // function addServiceOverrides

        /**
         *
         * Merge the config from the cookie onto the config from dataLayer onto the defaults
         *
         * @param {Object} modelConfiguration - configuration portion of dataLayer or similar
         *
         * @returns {Object} the merged configuration
         *
         */
        function mergeConfig(modelConfiguration) {
            var ceaConfig = getJsonKey(modelConfiguration, 'configuration.CEA'),
                cookieJarValue = getCookie(P13N_COOKIE_JAR, true) || {};

            config.personalizationIdCookieJarName = P13N_COOKIE_JAR;

            if (ceaConfig) {
                $.extend(config, ceaConfig);
            }

            if (cookieJarValue.config) {
                $.extend(config, cookieJarValue.config);
            }

            addServiceOverrides(config.additionalHeaders);
            utilsMergeConfig(config);

            return config;
        } // function mergeConfig

        /**
         * A callback function that collects results returned by alteration promises.
         */
        function processAlterationResults() {
            $.each(arguments, function (index, promiseResult) {
                var status;

                if (typeof promiseResult.status === 'string' &&
                    typeof promiseResult.selector === 'string'
                ) {
                    status = promiseResult.status === 'resolved' ? 'found' : 'notFound';
                    results[promiseResult.info] = results[promiseResult.info] || {};
                    results[promiseResult.info][status] = results[promiseResult.info][status] || [];
                    results[promiseResult.info][status].push(promiseResult.selector);
                }
            });
        }

        // Returns a deferred promise for later execution.
        function queueGroupAlterations(alterationGroup) {
            return $.when.apply($, getAlterationPromises(alterationGroup)).then(processAlterationResults);
        }
        /**
         *
         * Queues alteration method groups into a chain of dependent promises.
         *
         * @param {array} alterationGroups An array of alterations grouped by priority.
         *
         * @returns {Object} An executed highest priority promise with chained lower priority promises.
         *
         */
        function queueAlterations(alterationGroups) {
            var group = alterationGroups.pop();

            // Check is there are higher priority groups.
            if (alterationGroups.length > 0) {
                return queueAlterations(alterationGroups).then(function () {
                    queueGroupAlterations(group);
                });
            } else if (group) {
                // Return an executed top priority promise with accordingly
                // chained lower priority not executed promises.
                return queueGroupAlterations(group);
            }
        }

        /**
         *
         * Bubbles an object key.desktop or key.mobile directly to key.  Modifies the object
         *
         * @param {Object} obj The object containing the key
         * @param {string} key The name of the key that may be a value or an object
         * @param {*} notDefined fallback value should the object not be configured properly
         */
        function updateAttributeForView(obj, key, notDefined) {
            var returnValue = obj[key],
                viewType;

            if (Personalization.dataLayer && Personalization.dataLayer.device) {
                viewType = Personalization.dataLayer.device.viewType;
            }
            if ($.isPlainObject(returnValue)) {
                returnValue = returnValue[viewType];
            }
            obj[key] = returnValue || notDefined;
        }

        function addFlickerPreventionStylesheet(selectors) {
            var styleTag;

            if (selectors.length) {
                styleTag = '<style id=' + P13N_FPCSS_ID + '>' + selectors.join(', ') + ' {visibility: hidden;}</style>';
                if (document.readyState === 'loading') {
                    /* jshint -W060 */
                    document.write(styleTag);
                    /* jshint +W060 */
                } else {
                    $(document.head).append(styleTag);
                }
            }
        }

        /**
         *
         * reveal these elements flagging them as configured
         *
         * @param {Object} Locations the configured locations to find and flag
         * @param {string} locations.<key>.selector a jquery selector
         *
         * @returns {Array} of promised elements
         *
         */
        function revealConfigured(locations) {
            var polledElements = [];

            $.each(locations, function (locationName, location) {
                polledElements.push(
                    pollForElementStatus(
                        'configured',
                        pollForElement(location.selector, location.domReadyTimeout).then(
                            revealFlickerPreventedElement
                        ),
                        location.selector
                    ).then(processAlterationResults)
                );
            });
            return polledElements;
        }

        /**
         *
         * reveal all hidden elements as defined in the data layer
         *
         */
        function revealFlickerPreventedContent() {
            var totalTime = timing ? Date.now() - timing.fetchStart : 'unknown';

            $('style#' + P13N_FPCSS_ID).remove();
            Debug.log('Total time from responseStart until revealFlicker complete: %sms', totalTime);
        } // revealFlickerPreventedContent

        /**
         *
         * reveal one hidden element
         *
         * @param {Object} $targetElement the element to reveal
         *
         */
        function revealFlickerPreventedElement($targetElement) {
            $targetElement.css('visibility', 'visible');
        } // revealFlickerPreventedElement

        /**
         *
         * reveal the element associated with a location should it be flicker prevented
         *
         * @param {string} locationName to reveal
         *
         */
        function revealFlickerPreventedLocation(locationName) {

            if (
                locationsFlickerPrevented[locationName] &&
                locationsFlickerPrevented[locationName].selector
            ) {
                revealFlickerPreventedElement($(locationsFlickerPrevented[locationName].selector));
            }
        } // revealFlickerPreventedLocation

        /**
         *
         * getAlterations that always run
         *
         * @param {Object} alterations
         * @param {string} alterations.<key>.alterationMethod
         * @param {string} alterations.<key>.contentSelector
         *
         * @returns {Object}
         */
        function getForcedAlterations(alterations) {
            var ALWAYS_EXECUTE_ALTERATIONS = ['remove'],
                locations = {};

            $.each(alterations, function (locationName, locationConfig) {
                if (
                    ALWAYS_EXECUTE_ALTERATIONS.indexOf(locationConfig.alterationMethod) > -1 ||
                    locationConfig.contentSelector
                ) {
                    locations[locationName] = locationConfig;
                }
            });
            return locations;
        }

        function processRules(model) {
            var rules = getJsonKey(model, 'configuration.CEA.rules'),
                disabled = false;

            if (rules) {
                Object.keys(rules).forEach(function (rule) {
                    var matchValue,
                        ruleValue;

                    if (!disabled) {
                        matchValue = getJsonKey(model, rule.replace(/-/g, '.'));
                        ruleValue = rules[rule];
                        if (typeof matchValue === 'string') {
                            matchValue = matchValue.toLocaleLowerCase();
                        }

                        if (typeof ruleValue === 'string') {
                            ruleValue = ruleValue.toLowerCase().split(',');

                            disabled = !ruleValue.some(function (curVal) {
                                return curVal === matchValue;
                            });
                        } else {
                            disabled = ruleValue !== matchValue;
                        }
                    }
                });
            }

            if (disabled) {
                Debug.logRemote('Disabled - rules did not match', {rules: rules});
            }
            return disabled;
        }

        function setAlterationConfigPriority(alterationConfig) {
            var deferredAlteration = deferAlteration(alterationConfig),
                deferredAlterationConfig = {
                    getPromise: deferredAlteration,
                    selector: alterationConfig.contentSelector || alterationConfig.selector
                };

            // 'html' is the injection method thus has higher priority
            if (alterationConfig.contentSelector) {
                deferredAlterationConfig.priority = 2;
            } else {
                deferredAlterationConfig.priority = 1;
            }
            return deferredAlterationConfig;
        }

        function getTestingLocations(pageKey) {
            return (
                    getCookie(P13N_LOCATIONS_COOKIE, true) ||
                    JSON.parse(localStorage[P13N_LOCATIONS_COOKIE] || '0') ||
                    {}
                )[pageKey] || [];
        }

        function mergePersonalizations(newPersonalizations, model) {
            var map = {},
                maxKey = 0;

            if (newPersonalizations.length) {
                if (!model.personalizations || model.personalizations.length === 0) {
                    model.personalizations = newPersonalizations;
                } else {
                    $.each(model.personalizations, function (key, personalization) {
                        var keyNum = +key;
                        maxKey = (keyNum && keyNum > maxKey) ? keyNum : maxKey;
                        if (personalization && personalization.location) {
                            map[personalization.location] = personalization;
                        }
                    });
                    newPersonalizations.forEach(function (personalization) {
                        if (personalization) {
                            if (personalization.location && map[personalization.location]) {
                                $.extend(map[personalization.location], personalization);
                            } else {
                                model.personalizations[++maxKey] = personalization;
                            }
                        }
                    });
                }
            }
        }

        function expandTemplateValues(alterationConfig, context) {
            $.each(alterationConfig, function (property, value) {
                if (typeof value === 'string' && value.indexOf('{{') >= 0) {
                    alterationConfig[property] = mustache.render(value, context);
                }
            });
        }

    },
        'Personalization',
        '$',
        'Debug',
        PEP,
        'performance.timing',
        'appendParams',
        'getDataLayer',
        'getAuthToken',
        'getCookie',
        'getJsonKey',
        'getPageKey',
        'getPersonalizationId',
        'mergeConfig',
        'pollForElementStatus',
        'getBoolean',
        'getInt',
        'setPersonalizationId',
        'pollForElement',
        'mustache',
        'getConversationId',
        'getPreferredLanguage',
        'ensureArray'
    );

} catch (e) {
    inject.resolve('Debug').logRemote(e);
}
