/* global inject, TESTING */
inject(function (Personalization, $, Debug, performance, store, PEP) {
    'use strict';

    var config = { // these are the fall back defaults when not overridden by mergeConfig
            authCookieIncludeSubDomains: true,
            authCookieName: 'pep_oauth_token',
            authTimeout: 600,
            authURL: '/authentication/get-client-token/',
            enableResourceTiming: true,
            longTimeoutEnabled: true,
            personalizationIdCookieJarName: 'personalization_jar',
            personalizationIdCookieName: 'id',
            languageSelectionJarAkaCookieJarName: 'languageSelection_jar_aka',
            languageSelectionJarAkaCookieName: 'preferredLanguage',
            logAjaxCommands: ['getAuthToken', 'fetchPersonalizations']
        },
        additionalDataMapping = {
            bookingSearch: addBookingSearchAttributes
        };

    $(document).ajaxSend(function (event, jqXHR, ajaxSettings) {
        ajaxSettings.startTime = Date.now();

        jqXHR.then(ajaxDoneHandler, ajaxDoneHandler);

        function ajaxDoneHandler() {
            ajaxSettings.endTime = Date.now();
        }
    });

    $(document).ajaxComplete(function (event, jqXHR, ajaxSettings) {
        var logData,
            message,
            resourceTimings;

        if (config.logAjaxCommands.indexOf(ajaxSettings.command) < 0) {
            return;
        }

        logData = {
            url: ajaxSettings.url,
            responseTime: ajaxSettings.endTime - ajaxSettings.startTime
        };

        if (TESTING) {
            // This is to support the test #4 in logging.test.js where performance is deleted
            performance = window.performance;
        }

        if (config.enableResourceTiming && performance && performance.getEntriesByType) {
            resourceTimings = performance.getEntriesByType('resource').filter(function (timing) {
                return timing.name.indexOf(ajaxSettings.url) > -1;
            });
            if (resourceTimings.length) {
                logData.resourceTiming  = resourceTimings[resourceTimings.length - 1];
            }
        }

        if (jqXHR.state() === 'resolved') {
            message = '\'' + ajaxSettings.command + '\' call succeeded: ';
        } else {
            message = '\'' + ajaxSettings.command + '\' call failed: ';

            logData.errorThrown = jqXHR.textStatus;
            logData.responseHeaders = jqXHR.getAllResponseHeaders();
            logData.status = jqXHR.status;
            logData.textStatus = jqXHR.textStatus;
        }

        Debug.logRemote(message, logData);
    });

    store('appendParams', appendParams);
    store('getDataLayer', getDataLayer);
    store('getAuthToken', getAuthToken);
    store('getConversationId', getConversationId);
    store('getCookie', getCookie);
    store('getJsonKey', getJsonKey);
    store('getPageKey', getPageKey);
    store('getPersonalizationId', getPersonalizationId);
    store('getPreferredLanguage', getPreferredLanguage);
    store('mergeConfig', mergeConfig);
    store('pollForElementStatus', pollForElementStatus);
    store('getBoolean', getBoolean);
    store('getInt', getInt);
    store('setCookie', setCookie);
    store('setPersonalizationId', setPersonalizationId);
    store('pollForElement', pollForElement);
    store('ensureArray', ensureArray);

    /**
     *
     * Appends addition request params per service requirements to an object.  also returns the object
     *
     * @param {Object} base the object to modify
     *
     * @returns {Object} the modified object
     */
    function appendParams(base) {
        var FL_RESIDENT_AFFILIATION = 'Florida Resident',
            attributes = [],
            dataLayer = Personalization.dataLayer,
            addToAttributes = addAttribute.bind(null, attributes, dataLayer),
            personalizationId = getPersonalizationId();

        if ($.isPlainObject(base)) {
            if (personalizationId) {
                base.personalizationId = personalizationId;
            }

            if (dataLayer) {
                addToAttributes('age', 'age');
                addToAttributes('geo.country', 'geolocation-country');
                addToAttributes('geo.dmaCode', 'geolocation-dma');
                addToAttributes('geo.region', 'geolocation-region');
                addToAttributes('guestAffiliations', 'guest-affiliations-fl-res', function (affiliations) {
                    if (affiliations) {
                        return affiliations.split(',').map(function (affiliation) {
                            return $.trim(affiliation);
                        }).indexOf(FL_RESIDENT_AFFILIATION) > 0;
                    }
                });
                addToAttributes('customerId', 'swid');
                addToAttributes('availableGuestMedia', 'photopass-media-available', function (indicator) {
                    return getBoolean(indicator).toString();
                });
                addToAttributes('pageId', 'page-name', function () {
                    var config = dataLayer.configuration,
                        locale,
                        lang;

                    if (config) {
                        locale = config.contentLocale || 'us';
                        lang = config.contentLanguage || 'en';
                    } else {
                        locale = 'us';
                        lang = 'en';
                    }

                    return [
                        'wdpro',
                        (dataLayer.site || '').toLowerCase(),
                        locale,
                        lang,
                        dataLayer.siteSection || 'notset',
                        dataLayer.pageId
                    ].join('/');
                });

                if (Personalization.config.additionalDecisionData) {
                    Personalization.config.additionalDecisionData.forEach(function (val) {
                        if (additionalDataMapping[val]) {
                            additionalDataMapping[val](dataLayer, attributes);
                        }
                    });
                }
            }

            addAttribute(attributes, window, 'location', 'page-url', function (location) {
                if (location) {
                    return location.toString();
                }
            });
            addAttribute(attributes, document, 'referrer', 'referrer');
            addAttribute(attributes, navigator, 'userAgent', 'user-agent');

            base.channel = 'web';
            base.events = [
                {
                    attributes: attributes,
                    datetime: new Date().toISOString(),
                    eventType: 'd-decision-request'
                }
            ];
        }

        return base;
    }

    /**
     * This function will push the additional data onto attributes and apply any formatting
     *
     * @param {Object} attributes - values to be passed in decision request
     * @param {Object} dataLayer
     * @param {string} field - dot notation value of location for value in dataLayer
     * @param {string} attributeName - key name to be used when sending attributeName
     * @param {function} processor - Optional processor to be used on value
     */
    function addAttribute(attributes, dataLayer, field, attributeName, processor) {
        var value;

        function appendAttribute(name, value) {
            var keyPair = {
                name: name,
                value: value
            };

            attributes[attributes.length] = keyPair;
        }

        if (typeof field !== 'undefined') {
            value = getJsonKey(dataLayer, field);
            if (value !== undefined && value !== '' && value !== null) {
                if (typeof processor === 'function') {
                    value = processor(value);
                }

                if (value !== undefined && value !== '' && value !== null) {
                    if (Array.isArray(value)) {
                        value.forEach(appendAttribute.bind(null, attributeName));
                    } else {
                        appendAttribute(attributeName, value);
                    }
                }
            }
        }
    }

    /**
     *
     * Appends addition request params per service requirements to an object.  also returns the object
     *
     * @param {Object} dataLayer
     * @param {Object} attributes attributes to send with decision request
     */
    function addBookingSearchAttributes(dataLayer, attributes) {
        var addToAttributes = addAttribute.bind(null, attributes, dataLayer);

        function formatDate(strDate) {
            var parts,
                dt;
            if (strDate) {
                parts = strDate.split('/');
                dt = new Date(parts[2], (parseInt(parts[0], 10) - 1), parts[1]);
                return dt.getFullYear().toString() + '-' +
                    ('0' + (dt.getMonth() + 1)).slice(-2) + '-' +
                    ('0' + (dt.getDate())).slice(-2);
            }
        }

        function forceInt(str) {
            return parseInt(str, 10);
        }

        addToAttributes('bookingSearchArrivalDate', 'booking-search-arrival-date', formatDate);
        addToAttributes('bookingSearchDepartureDate', 'booking-search-departure-date', formatDate);
        addToAttributes(
            'bookingSearchDaysBetweenSearchAndArrival',
            'booking-search-days-between-search-and-arrival',
            forceInt
        );
        addToAttributes('bookingSearchLengthOfStayNights', 'booking-search-length-of-stay-nights', forceInt);
        addToAttributes('bookingSearchAdults', 'booking-search-adults', forceInt);
        addToAttributes('bookingSearchChildren', 'booking-search-children', forceInt);
        addToAttributes('bookingSearchInfants', 'booking-search-infants', forceInt);
        addToAttributes('bookingSearchChildrenAges', 'booking-search-children-ages');
        addToAttributes('bookingSearchResort', 'booking-search-resort-onesource');
        addToAttributes('bookingSearchAccessible', 'booking-search-accessible');
        addToAttributes('productsDisplayed', 'booking-search-available-rooms',
            function (productsDisplayed) {
                var bookingSearchAvailableRoomTypes = [],
                    primaryResort = (dataLayer.bookingSearchResort || '').split(';')[0];
                if (productsDisplayed) {
                    productsDisplayed.forEach(function (product) {
                        if (product.isAvailable && product.roomTypeId) {
                            if (primaryResort) {
                                if (product.parentResort === primaryResort) {
                                    bookingSearchAvailableRoomTypes.push(product.roomTypeId);
                                }
                            } else {
                                bookingSearchAvailableRoomTypes.push(product.roomTypeId);
                            }
                        }
                    });
                }
                return bookingSearchAvailableRoomTypes.join(',');
            }
        );
        addToAttributes('offerId', 'offer-id');
    }
    /**
     *
     * Reads the data layer object from the DOM
     *
     * @returns {Object}
     */
    function getDataLayer() {
        try {
            return $.parseJSON(atob($('[data-analytics]').attr('data-analytics')))[0];
        } catch (e) {
            return null;
        }
    }

    /**
     *
     * Fetches the auth token and caches it does not get fetched excessively
     *
     * @returns {Object} Promise (string) with the authToken string
     */
    function getAuthToken() {
        var authToken = authToken || getCookie(config.authCookieName),// attempt to fetch the value from cookie
            deferred = $.Deferred(), // the promise to return
            url = config.authURL; // url for the service to hit in case we need a new token

        if (authToken) {
            deferred.resolve(authToken);
        } else {
            $.ajax({
                beforeSend: function (xhr) {
                    Personalization.ajaxCall = xhr;
                },
                contentType: 'application/json',
                dataType: 'json',
                timeout: config.longTimeoutEnabled ? 0 : config.authTimeout,
                type: 'GET',
                url: url,
                command: 'getAuthToken'
            }).then(
                function getAuthTokenSuccess(data) {
                    var domain, // domain to use in saving the authToken to a cookie
                        hostname = window.location.hostname.toString(); // current hostname

                    if (hostname !== 'localhost') {
                        domain = (config.authCookieIncludeSubDomains ? '.' : '') + hostname;
                    }

                    setCookie(
                        config.authCookieName,
                        /*jshint camelcase: false */
                        data.access_token,
                        data.expires_in,
                        /*jshint camelcase: true */
                        '/',
                        domain,
                        (window.location.protocol === 'https:' ? true : false)
                    );

                    /*jshint camelcase: false */
                    deferred.resolve(data.access_token);
                    /*jshint camelcase: true */
                },
                function getAuthTokenFailure() {
                    deferred.reject();
                }
            );
        }

        return deferred.promise();
    }

    /**
     *
     * Retrieve ConversationId
     *
     * Need to elaborate for non-PEP platforms
     *
     * @returns {string|undefined}
     *
     */
    function getConversationId() {
        if (TESTING) {
            PEP = window.PEP;
        }
        return getJsonKey(PEP, 'Config.Recommender.conversationId');
    }

    /**
     *
     * Fetch a cookie
     *
     * @param {string} cookie - the name of the cookie to fetch
     * @param {boolean} decode - toggle to JSON decode the value
     *
     * @returns {string}
     */
    function getCookie(cookie, decode) {
        var re = new RegExp(cookie + '=([^;]+)'), // dynamic regex to parse document.cookie for the one you want
            value = re.exec(document.cookie); // the value out of the regex

        value = (value !== null) ? decodeURIComponent(value[1]) : null;
        if (decode) {
            value = JSON.parse(value);
        }

        return value;
    }

    /**
     *
     * retrieves a value from a JSON object at a specific address
     *
     * @param {Object} parsed - a parsed JSON object
     * @param {string} address - the address of the property to find ie. key.sub.property
     *
     * @returns {*|undefined}
     */
    function getJsonKey(parsed, address) {
        return address.split('.').reduce(function (node, prop) {
            if (typeof node !== 'undefined') {
                return node[prop];
            }
        }, parsed);
    }

    /**
     *
     * Read the pageKey from the DOM
     *
     * @returns {string}
     */
    function getPageKey() {
        return $('meta[name="pageKey"]').attr('content');
    }

    /**
     *
     * Fetch the personalizationId from the cookie
     *
     * @returns {string | Null}
     */
    function getPersonalizationId() {
        var cookieJar, // reference to cookie jar
            id = null; // the value to return

        cookieJar = getCookie(config.personalizationIdCookieJarName);
        if (cookieJar) {
            id = JSON.parse(cookieJar)[config.personalizationIdCookieName];
        }

        return id;
    }

    /**
     *
     * Fetch the preferredLanguage from the cookie
     *
     * @returns {string | Null}
     */
    function getPreferredLanguage() {
        try {
            var cookieJar, // reference to cookie jar
            preferredLanguage = null; // the value to return

            cookieJar = getCookie(config.languageSelectionJarAkaCookieJarName);
            if (cookieJar) {
                preferredLanguage = JSON.parse(cookieJar)[config.languageSelectionJarAkaCookieName];
            }
            return preferredLanguage;
        } catch(e) {
            console.error(e);
        }
    }

    function mergeConfig(overrides) {
        $.extend(config, overrides);
    }

    /**
     *
     * wraps the result of a promise in a resolved promise in order to be able to collect both
     *   rejected and fulfilled
     *      pollForElement calls in a when call, since when calls fire on all resolved or the first failed.
     *      There are times when we want a when call to fire when all settled (fulfilled | rejected)
     *
     * @param {*} info - additional data to pass through
     * @param {Promise} promise - to be wrapped and returned as resolved.
     * @param {*} selector - selector data to pass through
     *
     * @returns {Object}
     */
    function pollForElementStatus(info, promise, selector) {
        var newPromise = $.Deferred();

        promise.then(
            function (value) {
                newPromise.resolve({ info: info, selector: selector, status: 'resolved', value: value });
            },
            function (value) {
                newPromise.resolve({ info: info, selector: selector, status: 'rejected', value: value });
            }
        );

        return newPromise;
    }

    /**
     * convert string/int value to boolean value
     *
     * @param {string|int} value - value to be converted to boolean
     *
     * @returns {boolean}
     */
    function getBoolean(obj) {
        if (obj && (obj === true || obj === 'true' || obj === '1' || obj === 1)) {
            return true;
        }

        return false;
    }

    /**
     * convert string/int value to int value
     *
     * @param {string|int} value - value to be converted to int
     *
     * @returns {int|undefined}
     */
    function getInt(value) {
        if (!isNaN(value) && !isNaN(parseInt(value, 10))) {
            return parseInt(value, 10);
        }
    }

    /**
     *
     * Set a cookie
     *
     * @param {string} sKey - the cookie name
     * @param {Object|string} sValue - the value to write in the cooke.  If Object, set jsonEncode = true
     * @param {Date|number|string} vEnd - when the cookie should expire.  Number is seconds from now.
     * @param {string} [sPath] - the path to target the cookie to
     * @param {string} [sDomain] - the domain to target the cookie to
     * @param {boolean} [bSecure] - limit the cookie to HTTPS
     * @param {boolean} [jsonEncode] - should we JSON.stringify the value
     *
     * @returns {boolean}
     */
    function setCookie(sKey, sValue, vEnd, sPath, sDomain, bSecure, jsonEncode) {
        var sExpires = '';

        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
            return false;
        }

        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd;
                    break;
                case String:
                    sExpires = '; expires=' + vEnd;
                    break;
                case Date:
                    sExpires = '; expires=' + vEnd.toUTCString();
                    break;
            }
        }

        if (jsonEncode) {
            sValue = JSON.stringify(sValue);
        }

        document.cookie =
            encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) +
            sExpires +
            (sDomain ? '; domain=' + sDomain : '') +
            (sPath ? '; path=' + sPath : '') +
            (bSecure ? '; secure' : '');
        return true;
    }

    /**
     *
     * set the personalizationId into storage to use later or for others to use
     *
     * @param {string} personalizationId - the value
     */
    function setPersonalizationId(personalizationId) {
        var now = new Date(),
            oneYr = new Date(),
            cookieJar,
            cookieName,
            cookieVal,
            encodeJSON = false;

        oneYr.setFullYear(now.getFullYear() + 1);

        if (config.personalizationIdCookieJarName && config.personalizationIdCookieName) {
            cookieJar = getCookie(config.personalizationIdCookieJarName);
            if (cookieJar) {
                cookieVal = JSON.parse(cookieJar);
            } else {
                cookieVal = {};
            }
            encodeJSON = true;
            cookieVal[config.personalizationIdCookieName] = personalizationId;
            cookieName = config.personalizationIdCookieJarName;

            setCookie(
                cookieName,
                cookieVal,
                oneYr.toUTCString(),
                '/',
                undefined,
                window.location.protocol === 'https:',
                encodeJSON
            );
        }
    }

    /**
     *
     * Repeatedly check for element to exist so we can execute a callback with payload on the element.
     * Stops checking when page load complete
     *
     * @param {string} selector - a jQuery selector
     * @param {boolean} lastCall - is this the final time checking
     *
     * @return {promise} promise is fullfilled when element is found, and rejected after domReady
     */
    function pollForElement(selector, timeout, elementDeferred, comboDeferred) {
        var $elm = $(selector), // the element you are looking for
            intervalMs = 50; // interval to repeatedly poll for element

        elementDeferred = elementDeferred || $.Deferred();
        if ($elm.length > 0) {
            elementDeferred.resolve($elm);
        }

        comboDeferred = comboDeferred || promiseRace(elementDeferred, afterReadyPromise(timeout));
        if (comboDeferred.state() === 'pending') {
            setTimeout(pollForElement.bind(null, selector, timeout, elementDeferred, comboDeferred), intervalMs);
        }

        return comboDeferred.promise();
    }

    /**
     *
     * returns a promise that resolves or rejects with the result of the first promise to resolve or reject
     *
     * @param {Promise} promise1 - the first contestant in the race
     * @param {Promise} promise2 - the second contestant in the race
     *
     * @returns {Promise}
     */
    function promiseRace(promise1, promise2) {
        var deferred = $.Deferred();
        promise1.then(deferred.resolve, deferred.reject);
        promise2.then(deferred.resolve, deferred.reject);
        return deferred.promise();
    }

    /**
     *
     * returns a promise that rejects on domready or after a timeout after domready
     *
     * @param {int} [timeout] - the number of milliseconds after domready to reject
     *
     * @returns {Promise}
     */
    function afterReadyPromise(timeout) {
        var deferred = $.Deferred();
        $(timeout ? setTimeout.bind(window, deferred.reject, timeout) : deferred.reject);
        return deferred.promise();
    }

    /**
     * ensure that the argument passed is returned as an array
     *
     * @param element - the argument to guarantee is an array or is wrapped as an array
     *
     * @returns {Array}
     */
    function ensureArray(element) {
        if (element === undefined) {
            return [];
        }
        if (!Array.isArray(element)) {
            element = [element];
        }
        return element;
    }

},
'Personalization',
'$',
'Debug',
'performance',
'store',
window.PEP || (window.PEP = {})
);
