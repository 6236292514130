/* global inject */
inject(function ($) {
    'use strict';

    $.fn.orderElements = orderElements;

    /**
     * This plugin utilizes the placeholder concept to mark original locations
     * of elements that are being moved around (sorted).
     *
     * Options:
     *
     * select - Selector for elements that need to be sorted.
     * order - An array of IDs listed in desired resulting order.
     * byContent - Selector of a child element to create an ID from its content.
     * byAttribute - Attribute name to create an ID from its value.
     *
     * {
     * 	select: '.element-selector,.another',
     * 	indexOn: 'div',
     * 	byAttribute: 'data-foo',
     * 	order: [
     * 		'elementId-1',
     * 		'elementId-2',
     * 		'elementId-3'
     * 	]
     * }
     *
     * @param query A set of options that controls plugin behaviour
     * @returns {orderElements}
     */
    function orderElements(query) {

        // Iterate through all selected containers
        this.each(function () {
            var $this = $(this),
                $thisPlaceholder = $('<!-- placeholder -->'),
                $elements,
                $element,
                $placeholder,
                indexed;

            if (!$.isPlainObject(query) || !Array.isArray(query.order) || !query.select) {
                return;
            }

            // Find elements to sort
            $elements = $(query.select, $this);

            if ($elements.length === 0) {
                return;
            }

            // Add a placeholder and detach the container from the DOM
            $this.before($thisPlaceholder);
            $this.detach();

            // Index selected elements for easy look-up
            indexed = index($elements, query);

            // Iterate through IDs in the requested order
            query.order.forEach(function (id) {
                var position;
                // Sanitize input
                if (typeof id !== 'string') {
                    return;
                }

                // Look up the current position of an element by its ID
                position = $.inArray(normalize(id), indexed.ids);

                // Skip if an element has not been found
                if (position < 0) {
                    return;
                }

                // Extract a reference to an element at the current position and a placeholder for re-insertion
                $element = indexed.elements.splice(position, 1)[0];
                $placeholder = indexed.placeholders.shift();

                // Remove processed ID from the list
                indexed.ids.splice(position, 1);

                // Re-insert an element at the new location and remove the placeholder
                $placeholder.replaceWith($element);
            });

            // Process the rest of elements in case the order list is shorter than the list of targeted elements
            while (($placeholder = indexed.placeholders.shift())) {
                $element = indexed.elements.shift();
                $placeholder.replaceWith($element);
            }

            $thisPlaceholder.replaceWith($this);
        });

        return this;
    }

    /**
     * Creates a map of elements, their placeholders and corresponding IDs.
     *
     * The resulting map is a set of parallel but asynchronous arrays.
     *
     * @param elements
     * @param query
     * @returns {{elements: Array, ids: Array, markers: Array}}
     */
    function index(elements, query) {
        var result = {
            ids: [],
            placeholders: [],
            elements: []
        };

        elements.each(function (k, element) {
            var $element = $(element),
                $idContainer,
                id,
                $placeholder;

            // Process order By parameter and extract an ID
            $idContainer = query.indexOn ? $($element.find(query.indexOn)[0]) : $element;
            id = query.byAttribute ? ($idContainer.attr(query.byAttribute) || '').trim() : $idContainer.text();

            // Create a placeholder for a sorted element re-insertion
            $placeholder = $('<!-- placeholder -->');

            // Capture an ID that an element identified by
            result.ids.push(normalize(id));
            // Store a reference to the placeholder
            result.placeholders.push($placeholder);
            // Store a reference to the element
            result.elements.push($element);

            $element.before($placeholder);
        });

        return result;
    }

    /**
     * Normalize an ID for easy comparison, remove special characters, etc.
     *
     * @param string
     * @returns {string}
     */
    function normalize(string) {
        if (string) {
            return string.replace(/[^\x00-\x7F]/g, '').toLowerCase();
        }
    }
},
'$'
);
