/* global inject */
inject(function ($) {
    'use strict';

    $.fn.updateAttributes = updateAttributes;

    function updateAttributes(attributes) {
        var $node = this;
        $.each(attributes || {}, function (attr, newVal) {
            var curAttrVal;

            attr = attr.toLowerCase();
            if (attr !== 'id') {
                if (attr === 'url') {
                    attr = 'src';
                }

                curAttrVal = $node.attr(attr);

                if (typeof curAttrVal !== 'undefined') {
                    if (attr === 'src') {
                        // hide until image loaded since load may come after DOM ready removal of styles
                        $node.css('visibility', 'hidden');
                    }
                    $node.attr(attr, newVal);
                }
            }
        });
        return $node;
    }
},
'$'
);
